span.circulo_blue {
    background: #5178D0;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 2.1em;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
}

.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 576px) {
    .bd-example {
      padding: 1.5rem;
      margin-right: 0;
      margin-left: 0;
      border-width: 1px;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
  
  .bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem -0.75rem 0;
    border: solid #dee2e6;
    border-width: 1px;
  }

  .dropdown-menu {
    background-color: black;
  }

  .Embed-container {
    width: 100%;
    height: 69vh;
    margin: 1% auto;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .dropdown-item {
    background-color: white;
  }

.navbar-dark .navbar-nav .nav-link {
  color: black; /*rgba(255, 255, 255, .55);*/
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: black; /*rgba(255, 255, 255, .55);*/
}

